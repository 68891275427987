.slick-arrow{
    color: white !important;
    
}
.slick-arrow::before{
    color: var(--primary-dark-blue)  !important;
}



.slick-prev {
    left: 0;
    z-index: 10;
}

.slick-next {
    right: 0;
    z-index: 10;
}

.slick-list {
    margin-inline: 48px;
}

.slick-slide {
    margin-right: 24px;
}