.custom-highlight {
  background-color: #3298fd;
  color: white;

}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
.changing-text {
  background: linear-gradient(90deg, #ff2a2a, #a6149c, #3a36f4); /* Adjust colors as needed */
  color:white;
  animation: gradientAnimation 3s infinite;
  background-size: 200% 100%;

}


::selection {
  background-color: #3298fd;
  color: white;
}

.ql-editor {
  /* height: 80vh; */
  font-size: 15pt !important;
  /* white-space: pre!important; */
  font-family: "Times New Roman", Times, serif;
  /* font-size: 12pt; */
  /* line-height: 1em; */
  border-radius: 12px;
}

.ql-editor p {
  margin-bottom: 1em !important;
}



/* Ensure last paragraph doesn't have extra margin */
.ql-editor p:last-child {
  margin-bottom: 0;
}

.ql-container {
  overflow: hidden !important;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.loading-text {
  background-color: #e0e0e0;
  display: inherit;
  height: auto;
  width: fit-content;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    background-color: #f0f0f0;
  }
  50% {
    background-color: #e0e0e0;
  }
  100% {
    background-color: #f0f0f0;
  }
}
.red {
  background-color: red;
  color: blue;
}

